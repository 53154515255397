



.pagination-container {
    display: flex;
    list-style-type: none;
    align-items: center;
  }
   
  
  .pagination-container .pagination-item{
      padding: 8px;
      text-align: center;
      margin: 0px 8px;
      color: #000000;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.75px;
      border-radius: 6px;
      line-height: 20px;
  }
  
  .pagination-container .pagination-item.dots:hover{
      background-color: transparent;
      cursor: default
  }
  
  .pagination-container .pagination-item:hover{
      background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
  }
  
  .pagination-container .pagination-item.selected{
       background-color: #0A49BA;
       color: #fff;
  }
  
  .pagination-container .pagination-item .arrow::before{
       position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid #323232;
          border-top: 0.12em solid #323232;
  }
  
  
  .pagination-container .pagination-item .arrow.left{
          transform: rotate(-135deg) translate(-50%);
  }
  
  .pagination-container .pagination-item .arrow.right{
          transform: rotate(45deg);
  }
  
  .pagination-container .pagination-item.disabled{
       pointer-events: none;
  }
  
  .pagination-container .pagination-item.disabled .arrow::before{
      border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
  }
  
  .pagination-container .pagination-item.disabled .arrow:hover{
       background-color: transparent;
          cursor: default;
  }
  

  .show_div{
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    margin-left: 20px;
    display: none;
  }
  .show_number{    
    background: #EFF6EF;
    border-radius: 6px;
    padding: 8px;
    line-height: 20px;
    letter-spacing: 0.75px;   
  }


  /* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {

    .show_div{
        color: #000000;
        font-weight: 500;
        font-size: 14px;
        margin-left: 20px;
        display: block;
      }
      .show_number{    
        background: #0A49BA;
        color: #fff;
        border-radius: 6px;
        padding: 8px;
        line-height: 20px;
        letter-spacing: 0.75px;
      }
    
}


