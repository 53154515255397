@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


 /* Lota fonts import */
 @font-face {
  font-family: "Lota-300";
  src: url("../src/fonts/Lota/Los\ Andes\ \ Lota\ Grotesque\ Thin.otf");
}

@font-face {
  font-family: "Lota-400";
  src: url("../src//fonts/Lota/Los\ Andes\ \ Lota\ Grotesque\ Regular.otf");
}

@font-face {
  font-family: "Lota-600";
  src: url("../src//fonts/Lota/Los\ Andes\ \ Lota\ Grotesque\ Semi\ Bold.otf");
}

/* Graphix font import */
@font-face {
  font-family: "Graphix-400";
  src: url("../src/fonts/Graphix/GraphikRegular.otf");
}

@font-face {
  font-family: "Graphix-500";
  src: url("../src/fonts/Graphix/GraphikMedium.otf");
}

@font-face {
  font-family: "Graphix-600";
  src: url("../src/fonts/Graphix/GraphikSemibold.otf");
}

body {
    scroll-behavior: smooth;
}

.authbg{
    background: #F1F4FC;
    background-image: radial-gradient(50% 50% at 50% 50%, rgba(247, 248, 249, 0) 0%, #F7F8F9 100%);
    background-repeat: no-repeat;
    min-height: 100vh;
    background-size: 100%; 
}


/* PIN styles */
.pincode-input-container .pincode-input-text{
    border-radius: 6px;
    margin-right: 5px !important;
    appearance: none;
  }
  
  .pincode-input-container .pincode-input-text:last-child{
    margin-right: 0px !important;
  }

/* react tel input styles */
.react-tel-input {
  font-family: 'Poppins',sans-serif !important;
  font-size: 14px !important;
}

.react-tel-input .flag-dropdown {
  background-color: #fff !important;
  border: 1px solid #D5DAE1 !important;
  border-right: none !important;
  border-radius: 8px 0 0 8px !important;
}

.react-tel-input .flag-dropdown:hover{
  border: 1px solid #D5DAE1 !important;
  border-right: none !important;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 14px !important;
}

.react-tel-input .selected-flag .arrow {
  left: 22px !important;
}

/* radio buttons */
input[type="radio"] {
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;

  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;

  /* The background will be the radio dot's color. */
  background: #DEFFE5;

  /* The border will be the spacing between the dot and the outer circle */
  border: 3px solid #FFF;

  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #00D67D;
  
}

input[type="radio"]:checked {
  box-shadow: 0 0 0 1px #00D67D;
  background-color: #00D67D;
}
  
  